<template>
  <div class="questionnaire">
    <Header appType="questionnaire"/>
    <div class="gradiant-bar"></div>
    <MainId />

    <div class="questionnaire-body container">
      <div class="v-spacer-40" />
      <h1 class="section-title">Interested in a business loan?</h1>
      <p class="section-desc">Let's start a conversation. Please answer a few questions to help us gain a better understanding of your needs:</p>
      <div class="v-spacer-10" />
      <div class="section-summary">
        <ul>
          <li v-if="isExisting == true">Existing commercial loan</li>
          <li v-if="isExisting == false">New Loan</li>
          <li class="dot" v-if="loanType">&#8226;</li>
          <li v-if="loanType">{{ convertLoanType() }}</li>
          <li class="dot" v-if="loanAmount">&#8226;</li>
          <li v-if="loanAmount">{{ dollarAmount }}</li>
          <li class="dot" v-if="loanPurpose">&#8226;</li>
          <li v-if="loanPurpose">{{ convertLoanPurpose() }}</li>
          <li class="dot" v-if="loanCollateral">&#8226;</li>
          <li v-if="loanCollateral">{{ convertLoanCollateral() }}</li>
          <li class="dot" v-if="businessType">&#8226;</li>
          <li v-if="businessType">{{ convertBusinessType() }}</li>
          <li class="dot" v-if="isJointApplication">&#8226;</li>
          <li v-if="isJointApplication">{{ convertJointApplication() }}</li>
        </ul>
      </div>
      <div class="v-spacer-30" />

      <question-existing v-model="isExisting" v-if="isExisting == null" id="question-existing" name="question-existing-name"/>
      <question-loan-type v-model="loanType" v-if="isExisting != null && loanType == null" id="question-loan-type" name="question-loan-type-name"/>
      <question-loan-amount v-model="loanAmount" v-if="loanType != null && loanAmount == null" id="question-loan-amount" name="question-loan-amount-name"/>
      <question-loan-purpose v-model="loanPurpose" v-if="loanAmount != null && loanPurpose == null" :loanType="loanType" id="question-loan-purpose" name="question-loan-purpose-name"/>
      <question-loan-collateral v-model="loanCollateral" v-if="loanPurpose != null && loanCollateral == null" :loanType="loanType" id="question-loan-collateral" name="question-loan-collateral-name"/>
      <question-business-type v-model="businessType" v-if="loanCollateral != null && businessType == null"  :loanType="loanType" id="question-business-type" name="question-business-type-name"/>
      <question-joint-application v-model="isJointApplication" v-if="businessType != null && isJointApplication == null" @finalCardUpdate="finalCardUpdate" id="question-joint-app" name="question-joint-app-name"/>

      <div role="status" aria-live="polite" class="result" v-if="isJointApplication != null && decision">
        <img class="form-image" srcset="@/assets/images/form.png 1x, @/assets/images/form-2x.png 2x" src="@/assets/images/form-2x.png" alt="" />
        <h2 class="result-title section-title-small">{{decision['title']}}</h2>
        <div class="section-summary" v-html="decision['steps']"/>
        <div class="v-spacer-30" />
        <div class="btn-container">
          <button type="button" class="continue-btn" @click="handleContinue" data-test="questionnaire-btn-continue">
            <span class="continue-text">{{decision['button']}}</span>
          </button>
        </div>
      </div>
      <div class="v-spacer-30" />
      <button type="button" v-if="isExisting != null" class="back-btn" @click="handleBack" data-test="ll-wiz-back">Back</button>
      <div class="v-spacer-60" />
    </div>

    <Footer />
  </div>
</template>

<script>
import CommercialAPI from '@/app/ll-commercial-api'
import Header from '@/components/Header'
import MainId from '@/components/MainId'
import Footer from '@/components/Footer'
import QuestionExisting from './Question_Existing.vue'
import QuestionLoanType from './Question_LoanType.vue'
import QuestionLoanAmount  from './Question_LoanAmount.vue'
import QuestionLoanPurpose from './Question_LoanPurpose.vue'
import QuestionLoanCollateral from './Question_LoanCollateral.vue'
import QuestionBusinessType from './Question_BusinessType.vue'
import QuestionJointApplication from './Question_JointApplication.vue'
import { mapActions, mapGetters } from 'vuex'
import utils from '../../app/utils'
import env from '../../app/env'

export default {
  name: 'Questionnaire',
  components: {
    Header,
    MainId,
    Footer,
    QuestionExisting,
    QuestionLoanType,
    QuestionLoanAmount,
    QuestionLoanPurpose,
    QuestionLoanCollateral,
    QuestionBusinessType,
    QuestionJointApplication,
  },
  data () {
    return {
      isExisting: null,
      loanType: null,
      loanAmount: null,
      loanPurpose: null,
      loanCollateral: null,
      businessType: null,
      decision: null,
      isJointApplication: null,
      loansBusinessURL: env.getLoansBusinessURL(),

      sbaSteps: 'Thank you for your interest in a loan at First Hawaiian Bank. \
        Based on your needs, your business would be best suited for a \
        <a href="https://www.fhb.com/en/business/business-lines-of-credit">Business FirstLine®</a> line of credit or \
        <a href="https://www.fhb.com/en/business/business-loans">Business FirstTerm<sup>SM</sup></a> loan.'
    }
  },
  methods: {
    ...mapActions('questionnaire', [
      'setID', 'setBusinessType', 'setAmount'
    ]),
    finalCardUpdate () {
      this.decision = this.getDecision()
    },
    handleBack () {
      if (this.isJointApplication != null ) {
        this.isJointApplication = null
      } else if (this.businessType != null) {
        this.businessType = null
      } else if (this.loanCollateral != null) {
        this.loanCollateral = null
      } else if (this.loanPurpose != null) {
        this.loanPurpose = null
      } else if (this.loanAmount != null) {
        this.loanAmount = null
      } else if (this.loanType != null) {
        this.loanType = null
      } else if (this.isExisting != null) {
        this.isExisting = null
      }
    },
    async handleContinue () {
      const payload = {
        is_existing_customer:    this.isExisting,
        request_type:            this.decision.decision, // TODO figure out what the decision is
        loan_type:               this.loanType,
        request_amount:          this.loanAmount,
        request_dollar_amount:   utils.formatMoneyFromString(this.dollarAmount),
        loan_purpose:            this.loanPurpose,
        loan_collateral:         this.loanCollateral,
        business_type:           this.businessType,
        is_joint_application:    this.isJointApplication,
      }
      console.log(payload)
      // Call the Commercial API. Regardless of error, redirect them
      const resp = await CommercialAPI.postQuestionnaire(payload)
      if (resp.status == 200) {
        this.setID(resp.data.response.questionnaire_id)
        this.setBusinessType(this.businessType)
        this.dollarAmount = ''
      }

      let dPath = this.decision['route']
      if (dPath.includes("https")) window.open(dPath, 'blank')
      else this.$router.push({path: dPath})
    },
    getDecision() {
      // SBA - loan amount less than 75k and New Loan
      if (!this.isExisting && this.loanAmount == '5') {
        return {decision: 'sba', route: this.loansBusinessURL, title: 'Loans & Lines', steps: this.sbaSteps, button: 'Continue to Form' }
      }
      // Connect to Banker Simplified - adjust an existing loan
      if (this.isExisting || ((this.loanType == 'construction' || this.loanType == 'nonprofit' || this.loanType == 'realestate') && this.loanAmount == 'mil' && this.loanPurpose == 'other' && this.loanCollateral == 'other')) {
        return {decision: 'banker', route: '/connectToBankerSimplified/banker', title: 'Connect to Banker Simplified', steps: 'Thank you for your interest in a loan at First Hawaiian Bank. Welcome back to First Hawaiian Bank. As you are an existing customer, please fill out the Connect to Banker Simplified form.', button: 'Continue to Form'}
      }
      // Personal Financial Statement - New loan and Sole proprietorship
      if (!this.isExisting && this.businessType == 'sole' && (this.loanAmount == '75' || this.loanAmount == '5') && this.loanPurpose != 'other' && (this.loanCollateral == 'assets' || this.loanCollateral == 'vehicle' || this.loanCollateral == 'deposits' || this.loanCollateral == 'none')) { // loanAmount == '5' correct? 
        return {decision: 'personal_financial', route: '/personalFi/personalfinancial', title: 'Personal Financial', steps: 'Thank you for your interest in a loan at First Hawaiian Bank. Please fill out the Personal Financial Statement.', button: 'Continue to Form'}
      }
      // greater than 75k,less than 500k and New loan.
      if (!this.isExisting & this.businessType != 'sole' && (this.loanAmount == '75') && this.loanPurpose != 'other' && (this.loanCollateral == 'assets' || this.loanCollateral == 'vehicle' || this.loanCollateral == 'deposits' || this.loanCollateral == 'none')) {
        return {decision: 'business_credit', route: '/credit/filer', title: 'Apply For Business Credit', steps: 'Thank you for your interest in a loan at First Hawaiian Bank. Please fill out the Business Credit Application.', button: 'Continue to Form'}
      }
      return {decision: 'banker', route: '/connectToBanker', title: 'Connect to Banker', steps: 'Thank you for your interest in a loan at First Hawaiian Bank. Based on your choices, you will need to meet with a banker. Please fill out the following form to connect with a banker.', button: 'Continue to Form'}
    },
    convertLoanType() {
      switch (this.loanType) {
        case 'small':
          return 'Business Loan'
        case 'revolving':
          return 'Revolving Line of Credit'
        // case 'construction':
        //   return 'Construction and Renovation Loan'
        case 'nonprofit':
          return 'Lending to Nonprofit Organizations'
        case 'realestate':
          return 'Business Owner-Occupied Real Estate Loan'
      }
    },
    convertLoanPurpose() {
      switch (this.loanPurpose) {
        case 'working':
          return 'Working Capital'
        case 'equipment':
          return 'Equipment/Vehicle Acquisition'
        case 'estate':
          return 'Real Estate Purchase'
        case 'startup':
          return 'Business Startup'
        case 'other':
          return 'Other'
      }
    },
    convertLoanCollateral() {
      switch (this.loanCollateral) {
        case 'estate':
          return 'Real Estate'
        case 'investments':
          return 'Investments/Insurance'
        case 'assets':
          return 'Company Assets'
        case 'vehicle':
          return 'Vehicle'
        case 'deposits':
          return 'Bank Deposits'
        case 'none':
          return 'None'
        case 'other':
          return 'Other'
      }
    },
    convertBusinessType() {
      switch (this.businessType) {
        case 'sole':
          return 'Sole Proprietorship'
        case 'partnership':
          return 'General Partnership'
        case 'llc':
          return 'Limited Liability Corporation (LLC)'
        case 'limited':
          return 'Limited Partnership'
        case 'corp':
          return 'Corporation'
        case 'other':
          return 'Other (e.g., non-profit)'
      }
    },
    convertJointApplication() {
      switch (this.isJointApplication) {
        case true: 
          return "Joint Application"
        case false:
          return "Individual Application"
      }
    },
  },
  computed: {
    ...mapGetters('questionnaire', [
      'amount'
    ]),
    dollarAmount: {
      get() { return this.amount },
      set(v) { this.setAmount(v) }
    }
  }
}
</script>

<style lang="scss">
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.section-title {
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  text-align: left;

  @include media-breakpoint-up(sm) {
    font-size: 2.3rem;
  }
}

.section-title,
.section-title2 {
  margin: 0;
  text-align: center;
}
.section-desc {
  text-align: center;
  margin-top: 0;
}
.section-summary {
  color: $grayDark;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
}
.result .section-summary {
  font-size: 1rem;
}
.section-title-small {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
}
ul {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
li {
  padding-right: 0.25em;
  padding-left: 0.25em;
}
.row-2 {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 2.5em 2.5em;
  justify-content: center;
  justify-items: center;
  align-items: stretch;
  width: 100%;

  > * {
    flex-basis: 100%;

    @include media-breakpoint-up(md) {
      flex-basis: calc(50% - 2.5em / 2);
    }
  }
}
.row-3 {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 2.5em 2.5em;
  justify-content: center;
  justify-items: center;
  align-items: stretch;
  width: 100%;

  > * {
    flex-basis: 100%;

    @include media-breakpoint-only(md) {
      flex-basis: calc(50% - 2.5em / 2);
    }

    @include media-breakpoint-up(lg) {
      flex-basis: calc(100% / 3 - 5em / 3);
    }
  }
}
.back-btn {
  width: 100px;
}
.card-title {
  font-size: 1.2rem;
  font-weight: 900;
  line-height: 1.5;
  margin-bottom: 0.25em;
  text-align: center;
}
.card-description {
  line-height: 1.5;
  text-align: center;
}
.card-special {
  text-align: center;
  padding: 1.5em 0;
}
.card {
  align-items: stretch;
  border: 0;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  text-align: center;
  width: 100%;

  .card-content {
    box-shadow: 0 0.5rem 1.5rem 0 rgb(0 0 0 / 10%);
    display: block;
    cursor: pointer;
    padding: 2em;
    transition: 0.3s;
    width: 100%;
    height: 100%;
  }
  
  /*&:hover,
  &:focus,
  &:active {
    span {
      background-color: $orangeFaint;
      box-shadow: 0 0.5rem 1.5rem 0 rgb(129 30 36 / 50%);
      cursor: pointer;
    }
  }*/
}
.back-btn {
  margin-top: 2em;
}
.btn-container {
  display: block;
}
.button-ctn {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.continue-btn {
  min-width: 120px;
  padding: 8px 32px;
  width: auto;
  height: auto;
  text-transform: uppercase;
}
.result {
  align-items: center;
  border: 0;
  box-shadow: 0 0.1rem .75rem 0 rgb(0 0 0 / 10%);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 3rem 2rem;
  transition: 0.3s;
  width: 100%;

  @include media-breakpoint-up(md) {
    max-width: 550px;
  }
}
.result-title {
  margin: 0;
}

.dot {
  color: $red;
}
fieldset .card-group,
fieldset .card-group.input-line {
  border: 1px solid $white;
}
fieldset:focus-within .card-group {
  border: 1px dotted fade-out(blue, 0.8);
}
input[type="radio"]:checked {
/*  background-color: white;
  border-radius: 4px;
  border-color: orange;
  box-shadow: 0 0 10px red;*/

  + .card-content {
    background-color: $yellowFocus;
    outline: 1px solid $yellow;
    transition: 0.2s;
    //box-shadow: 0 0.5rem 1.5rem 0 rgb(129 30 36 / 50%);
    cursor: pointer;
  }
}
input[type="radio"]:hover,
input[type="radio"]:focus,
input[type="radio"]:active {
  + .card-content {
    background-color: $yellowFocus;
    //box-shadow: 0 0.5rem 1.5rem 0 rgb(129 30 36 / 50%);
    cursor: pointer;
  }
}
legend {
  text-align: center;
  width: 100%;
}
/*.input-two-selected input:focus span,
.input-two input:focus span {
  padding: 20px 100px;
  background-color: orange;
  border: 1px solid orange;
  box-shadow: 0px 3px 0px rgba(176, 176, 176, 0.5);
  border-radius: 3px;
}*/
.form-image {
  width: 60px;
  height: auto;
  margin-bottom: 12px;
}
</style>
