const loansBusinessURLs = {
    staging: 'https://staging.loans.fhb-eng.com/business',
    prod: 'https://loans.fhb.com/business'
}

export default {
    getLoansBusinessURL() {
        if (window.location.href.indexOf('dev.') > -1) {
            return loansBusinessURLs.staging
        } else if (window.location.href.indexOf('staging.') > -1) {
            return loansBusinessURLs.staging
        } else if (window.location.href.indexOf('localhost') > -1) {
            return loansBusinessURLs.staging
        } else {
            return loansBusinessURLs.prod
        }
    },
}
